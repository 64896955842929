import { SET_FILTER } from "../constants"
import moment from "moment"
import { statusTabList } from "../../constants/statuses"

const initialState = {
  orders: {
    customer_id: null,
    branch_ids: undefined,
    courier_id: null,
    payment_type: undefined,
    sort_by: null,
    external_order_id: null,
    shipper_id: null,
    region_ids: [],
    isDownload: false,
  },
  extraOrders: {
    external_order_id: undefined,
    start_date: moment().add(-1, "d").format("YYYY-MM-DD"),
    end_date: moment().add(1, "d").format("YYYY-MM-DD"),
    payment_type: undefined,
    tabValue: statusTabList[0].id,
  },
  tabValue: {
    id: statusTabList[0].id,
  },
}

export default function filterReducer(state = initialState, { payload, type }) {
  switch (type) {
    case SET_FILTER:
      return {
        ...state,
        [payload.table_name]: {
          ...state[payload.table_name],
          ...payload.filter,
        },
      }
    default:
      return state
  }
}
