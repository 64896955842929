import React from "react"
import ReactDOM from "react-dom"
import App from "./App.jsx"
import reportWebVitals from "./reportWebVitals"
import "tailwindcss/tailwind.css"
import "./index.css"
import "./config/defaultSettings"
import "../src/locales/i18n"

ReactDOM.render(<App />, document.getElementById("root"))

reportWebVitals()
