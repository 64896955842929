export const SET_AUTH_CREDENTIALS = `SET_AUTH_CREDENTIALS`
export const SET_PERMISSIONS = `SET_PERMISSIONS`
export const SET_AUTH_TOKENS = `SET_AUTH_TOKENS`
export const REFRESH_ACCESS_TOKEN = `REFRESH_ACCESS_TOKEN`

export const CLEAR_ON_SIGNOUT = `CLEAR_ON_SIGNOUT`
export const CHANGE_LANGUAGE = `CHANGE_LANGUAGE`

export const IS_LOADING = "IS_LOADING"
export const MENU_KEY = "MENU_KEY"

export const ADD_NEW_ALERT = "ADD_NEW_ALERT"
export const DELETE_ALERT = "DELETE_ALERT"
export const SET_GLOBAL_ALERT = "SET_GLOBAL_ALERT"
export const SET_GLOBAL_ALERT_HEIGHT = "SET_GLOBAL_ALERT_HEIGHT"

export const SET_USER_VERIFIED = "SET_USER_VERIFIED"
export const SET_STATUS_ID = "SET_STATUS_ID"

export const SET_FILTER = "SET_FILTER"
