import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from "./en.json"
import ru from "./ru.json"
import uz from "./uz.json"

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
  uz: {
    translation: uz,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: "ru",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
})

export default i18n

// import i18n from "i18next"
// import { initReactI18next } from "react-i18next"
// import Backend from "i18next-http-backend"
// import LanguageDetector from "i18next-browser-languagedetector"

// import en from "./en.json"
// import ru from "./ru.json"
// import uz from "./uz.json"

// const resources = {
//   en: {
//     translation: en,
//   },
//   ru: {
//     translation: ru,
//   },
//   uz: {
//     translation: uz,
//   },
// }

// i18n
//   .use(Backend)
//   .use(initReactI18next)
//   .use(LanguageDetector)
//   .init({
//     resources,
//     lng: "ru",

//     fallbackLng: "ru",

//     whiteList: ["ru", "en", "uz"],
//     detection: {
//       order: ["localStorage"],
//       caches: ["localStorage"],
//     },

//     // have a common namespace used around the full app
//     debug: false,

//     // have a common namespace used around the full app

//     keySeparator: false,

//     interpolation: {
//       escapeValue: false,
//     },
//   })

// export default i18n
