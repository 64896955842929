export default {
  primary: {
    900: "#002266",
    600: "#0E73F6",
    500: "#4094F7",
    100: "#EBF7FF",
  },
  grey: {
    dark: "#212529",
    medium: "#9AA6AC",
    light: "#D7D7D7",
    5: "#F6F8F9",
  },
  white: "#ffffff",
}
